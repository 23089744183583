<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="lg"
  >
    <ui-media-viewer
      class="rounded-lg overflow-hidden"
      :init-index="initIndex"
      :attachments="attachments"
    />
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  type Props = {
    attachments: Attachment[] | MessageAttachment[];
    initIndex?: number;
  };
  defineProps<Props>();
  const emit = defineEmits(['update:modelValue']);
  createModalState(emit);
</script>
